:root {
    --bs-primary: red !important;
}

.btn-language {
    position: relative;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: fit-content;
    background-color: rgb(245, 245, 245);
    border-radius: 5px;
    box-shadow: none;
}

.btn-language:hover {
    background-color: rgb(245, 245, 245);
}

.language .icon {
    margin-right: 15px;
}

.language .text {
    font-weight: 600;
    font-size: 16px;
    color: #333;
    margin-right: 45px;

}

.language .icon span {
    vertical-align: 0 !important;

}

.language .arrow {
    position: absolute;
    z-index: 1;
    right: 0px;
    display: flex;
    align-self: center;
    width: 14px;
    height: 12px;
    margin-right: 12px;
    top: 13px;

    background-repeat: no-repeat;
    transition: all 0.3s ease 0s;
}

.layout .left .companyInfo {
    border-bottom: 1px solid #dedede;
    border-top: 1px solid #dedede;
    padding-left: 13px;
    padding-right: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    align-content: space-around;
    color: #dedede;
    background-color: #273643;
}

.layout .left .companyInfo .avatar {
    width: 25%;
    border-radius: 50%;
}

.layout .left .companyInfo .avatar img {
    border-radius: 50%;
    width: 53px;
    border: 2px solid #dedede;
}

.layout .left .companyInfo .infos {
    width: 75%;
    padding-left: 15px;
    
}

.layout .left .companyInfo .infos .nameSurname {
    font-size: 14px;
    font-weight: bold;
    color: white;
}

.layout .left .companyInfo .infos .companyName {
    font-size: 12px;
    font-weight: 600;
    color: white;
}

.layout a:link {
    text-decoration: none;
}

.layout .ant-menu-item-selected {
    background-color: #1677ff;
    border-radius: 0 !important;
}

.trigger {

    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}



svg {
    display: inline !important;
    vertical-align: baseline !important;
}

.dashboard .cardIcon {
    padding: 15px;
    width: fit-content;
    font-size: 22px;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
}

.previewCompanyLogo {
    width: 150px;
}

.previewCompanyLogo img {
    width: 100%;
}




.steps .stepsLabel {

    padding: 12px 15px;
    color: rgba(26, 51, 83, .6);
    font-size: 12px;
    line-height: 1.5;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 15px;
    border-bottom: 1px solid #dedede;
    margin-bottom: 15px;
}

.steps ul {
    list-style-type: none;
    padding-left: 20px;

}

.steps ul li {

    position: relative;
    display: -webkit-flex;
    display: flex;
    height: 40px;
    -webkit-align-items: center;
    align-items: center;
    padding-left: 15px;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
}

.steps ul li.actives {
    color: #410099 !important;

    text-decoration: none;
    background-color: rgba(65, 0, 153, .1) !important;
    border-left: 3px solid #410099;
}

.steps ul li.completes {
    color: #333 !important;
    text-decoration: none;
}

.steps ul li.completes:after {
    content: "✓";
    position: absolute;
    right: 15px;
    color: #410099 !important;
}

.steps ul li.passive {
    color: #a1a1a1;
    text-decoration: line-through;
}

.checkboxPadding {
    padding: 5px;
}

.checkboxInput {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
    padding-right: 4px;
}

.colSetW {
    max-width: 100px;
}


.products {
    display: flex;
    align-items: center;
}

.products .img {
    padding-right: 15px;
    border-right: 1px solid rgb(222, 222, 222);
}

.products .img img {
    border-radius: 5px;
    width: 120px;
    height: 100%;
    object-fit: cover;
}

.products .prog {
    padding-left: 15px;
}

.products .detail {
    padding-left: 15px;
    border-left: 1px solid rgb(222, 222, 222);
    margin-left: 15px;
}

.products .detail .categories {
    margin-bottom: 5px;
}

.products .detail .recordDate {
    font-size: 12px;
    color: rgb(153, 153, 153);
}

.ant-progress-circle:where(.css-dev-only-do-not-override-ixblex).ant-progress-status-success .ant-progress-text {
    color: #410099 !important;
}

:where(.css-dev-only-do-not-override-ixblex).ant-result-success .ant-result-icon>.anticon {
    color: #410099 !important;
}

.rbc-month-view {
    border: 1px solid #f2f2f2;
    border-radius: 7px;

}

.rbc-header + .rbc-header {
    border-left: 1px solid #f2f2f2 !important;
}

.rbc-header {
    padding: 10px 3px !important;
    border-bottom: 1px solid #f2f2f2 !important;
}

.rbc-month-row + .rbc-month-row {
    border-top: 1px solid #f2f2f2 !important;
}

.rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #f2f2f2 !important;
}

.rbc-off-range-bg {
    background: #e6e6e6;
}



.rbc-selected-cell {
   background-color: rgba(65, 0, 153, .15) !important;
}

.rbc-today {
    background-color: rgba(65, 0, 153, .05) !important;
}
.rbc-date-cell {
    border-bottom: 1px solid #f2f2f2;
    font-weight: bold !important;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.035)!important;
    padding: 0 !important;
    /* 
   background-color: rgba(7, 1, 63, 0.527);
   color:#f2f2f2
   */
}

.rbc-date-cell .rbc-button-link {
    font-weight: bold !important;
}

.rbc-day-bg:not(.rbc-off-range-bg) {
    cursor: pointer;
}

.rbc-day-bg:not(.rbc-off-range-bg):hover {

    background-color: rgba(65, 0, 153, .15) !important;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}

.eventContainer {
    background-color: transparent !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    border-bottom:1px solid #f2f2f2 !important;
}

.rbc-event:focus {
    outline:none !important;
}

.eventContainer .ant-tag:hover {
    border:1px solid #333;
    background-color: #410099;
    color:#fff;
}

.bg-custom {
    background-color: #410099;
}

.rbc-header .badge {
    cursor: pointer;
}

.rbc-header .badge:hover {
    opacity: 0.7;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}

.rbc-off-range {
    color: #e1e1e1;
}




.flatpickr-calendar {
    padding: 10px !important;
    width: 100% !important;
    font-size: 14px !important;
    max-width: 640px;
}

.flatpickr-rContainer {
    width: 100% !important;
}

.flatpickr-days {
    width: 100% !important;
}

.flatpickr-day {
    max-width: none !important;
    height: auto !important;
    line-height: 48px !important;
}

.dayContainer {
   max-width: 100% !important;
    width: 100% !important;
}

.flatpickr-calendar .event {
    position: absolute;
    bottom: -15px;
    font-size: 10px;
    left: 0;
    width: 100%;
    font-weight: bold;
}

.flatpickr-calendar .event:hover .toolTip {
    display: block;
}

.flatpickr-calendar .event .toolTip {
    display: none;
    bottom: 0px;
    left: 0;
    min-width: 240px;
    line-height: normal;
    z-index: 9999;
    font-size: 12px;
    position: fixed;
    min-height: 32px;
    padding: 6px 8px;
    color: #fff;
    text-align: start;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0,0,0,.25);
    border-radius: 6px;
    box-shadow: 0 6px 16px 0 rgba(0,0,0,.08), 0 3px 6px -4px rgba(0,0,0,.12), 0 9px 28px 8px rgba(0,0,0,.05);
}


.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: #41009a !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: #41009a !important;
}


.loader-1 {
  
    width: 32px;
    -webkit-animation: loader-1-1 4.8s linear infinite;
    animation: loader-1-1 4.8s linear infinite;
  }
  @-webkit-keyframes loader-1-1 {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes loader-1-1 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  .loCenter {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .loader-1 span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 32px;
    width: 32px;
    clip: rect(0, 32px, 32px, 16px);
    -webkit-animation: loader-1-2 1.2s linear infinite;
    animation: loader-1-2 1.2s linear infinite;
  }
  @-webkit-keyframes loader-1-2 {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(220deg);
    }
  }
  @keyframes loader-1-2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(220deg);
    }
  }
  .loader-1 span::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 32px;
    width: 32px;
    clip: rect(0, 32px, 32px, 16px);
    border: 3px solid #ccc;
    border-radius: 50%;
    -webkit-animation: loader-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loader-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  }
  @-webkit-keyframes loader-1-3 {
    0% {
      -webkit-transform: rotate(-140deg);
    }
    50% {
      -webkit-transform: rotate(-160deg);
    }
    100% {
      -webkit-transform: rotate(140deg);
    }
  }
  @keyframes loader-1-3 {
    0% {
      transform: rotate(-140deg);
    }
    50% {
      transform: rotate(-160deg);
    }
    100% {
      transform: rotate(140deg);
    }
  }


  .languageDropDown {
    cursor: pointer;
    padding-top: 1px !important;
    margin-left: 10px;
    margin-right: 10px;
  }

  .languageDropDown .ant-space-item  {
    font-size: 16px;
  }
